import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home, Landing, EventDetail, Login, UserRegister, RegisterStepOne, RegisterStepTwo, RegisterStepThree, RegisterStepFour, Kyc, ExploreLanding, TicketLanding, Profile, ProfileView, MyMoney, PaymentAccount, HelpCenter, ForgotPassword, ResetPassword, BuyTicket, CreateEvent, ProfileUserView, ResellEvent, ResellTicketDetail } from "./pages";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Store } from "./redux/store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLECLIENTID } from "./constant/constant";
import RegisterStepThreeOne from "./pages/register/stepThreeOne";

function App() {

  return (
    <GoogleOAuthProvider clientId={GOOGLECLIENTID}>
      <Provider store={Store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router>
            <Routes>
              <Route
                caseSensitive
                path={"/*"}
                element={<Home />}
              >
                <Route 
                  index
                  caseSensitive
                  path={""}
                  element={<Landing />}
                />

                {/* Account & Registeration */}
                <Route 
                  caseSensitive
                  path={"account/login/"}
                  key={'Login'}
                  element={<Login />}
                />


                <Route 
                  caseSensitive
                  path={"account/forgot_password/"}
                  key={'ForgotPassword'}
                  element={<ForgotPassword />}
                />


                <Route 
                  caseSensitive
                  path={"account/reset/:id"}
                  key={'ResetPassword'}
                  element={<ResetPassword />}
                />

                <Route 
                  caseSensitive
                  path={`account/register/*`}
                  element={<UserRegister />}
                >
                  <Route 
                    caseSensitive
                    index
                    path={"step1"}
                    element={<RegisterStepOne />}
                  />
                  <Route 
                    caseSensitive
                    path={"step2"}
                    element={<RegisterStepTwo />}
                  />
                  <Route 
                    caseSensitive
                    path={"step3"}
                    element={<RegisterStepThree />}
                  />
                  <Route 
                    caseSensitive
                    path={"step31"}
                    element={<RegisterStepThreeOne />}
                  />
                  <Route 
                    caseSensitive
                    path={"step4"}
                    element={<RegisterStepFour />}
                  />
                </Route>

                <Route 
                  caseSensitive
                  path={"account/kyc/"}
                  element={<Kyc />}
                />


                {/* Explore Event */}
                <Route 
                  caseSensitive
                  path="explore/"
                  element={<ExploreLanding />}
                />


                {/* Event Ticketing */}
                <Route 
                  caseSensitive
                  path={"ticket/"}
                  element={<TicketLanding />}
                />


                {/* Event Detail */}
                <Route 
                  caseSensitive
                  path={`event/:id`}
                  element={<EventDetail />}
                />

                <Route 
                  caseSensitive
                  path={`:id`}
                  element={<EventDetail />}
                />

                {/* Event buy ticket */}
                <Route 
                  caseSensitive
                  path={`event/buy_ticket/:id`}
                  element={<BuyTicket />}
                />


                {/* User Profile */}
                <Route
                  caseSensitive
                  path={"profile/*"}
                  element={<Profile />}
                >
                  <Route  
                    caseSensitive
                    path="me"
                    element={<ProfileView />}
                  />
                  <Route  
                    caseSensitive
                    path="user/:id/"
                    element={<ProfileUserView />}
                  />
                  <Route  
                    caseSensitive
                    path="me/mymoney/"
                    element={<MyMoney />}
                  />

                  <Route  
                    caseSensitive
                    path="me/accounts/"
                    element={<PaymentAccount />}
                  />

                  <Route  
                    caseSensitive
                    path="helpcenter/"
                    element={<HelpCenter />}
                  />

                </Route>

                {/* Create Event */}
                <Route 
                  caseSensitive
                  path={`create_event/`}
                  element={<CreateEvent />}
                />
                <Route 
                  caseSensitive
                  path={`create_event/edit/:eventId`}
                  element={<CreateEvent />}
                />
                <Route 
                  caseSensitive
                  path={`create_resell_event/`}
                  element={<ResellEvent />}
                />
                <Route 
                  caseSensitive
                  path={`create_resell_event/edit/:ticketId/`}
                  element={<ResellEvent />}
                />
                <Route 
                  caseSensitive
                  path={`resell_ticket/:id`}
                  element={<ResellTicketDetail />}
                />

              </Route>
            </Routes> 
          </Router> 
        </LocalizationProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
