import { Outlet } from "react-router-dom"
import { AccountSelectModal, SplashScreen } from "../components";
import { useEffect, useState } from "react";
import { fetchUserLocation } from "../action/generalFunc";
import { useSelector } from "react-redux";
import { RootState, actionCreator } from "../redux";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

const Home = (props:any) => {
    const [showSplash, setShowSplash] = useState<boolean>(true);
    const [closeDisplay, setCloseDisplay] = useState<boolean>(false);
    const [ticketBuyAccount, setTicketBuyAccount] = useState<boolean>(false);
    const [ticketId, setTicketId] = useState<number | string | null>(null);
    const account = useSelector((state:RootState) => state.account);
    const dispatch = useDispatch();
    const { refreshToken, logout } = bindActionCreators(actionCreator, dispatch);
    
    useEffect(() => {
        const fetchLocation = async () => {
            try {
                await fetchUserLocation();
                await new Promise(resolve => setTimeout(resolve, 3000));
            }catch (e) {
                console.warn(e);
            }finally {
                setShowSplash(false);
                setTimeout(() => {
                    setCloseDisplay(true);
                },2000);
            }
        };
        fetchLocation();
    })

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        const refreshEntry = async () => {
            let now = new Date();
            if (now.getTime() < account.expiry) return;
            let nresult = await refreshToken(account.token);
            if (!nresult) {
                await logout();
            }
        }
        refreshEntry();
        const refreshTimer = setInterval(async () => {
            await refreshEntry();
        }, 60000);

        return () => clearInterval(refreshTimer);
    }, [account, refreshToken, logout]);

    return (
        <>
            {(!localStorage.getItem('userLocation') || localStorage.getItem('userLocation') === 'undefined') && (
                <SplashScreen visible={showSplash} closeDisplay={closeDisplay} />
            )}
            <Outlet context={{ setTicketBuyAccount, setTicketId }} />
            
            {ticketBuyAccount && ticketId && <AccountSelectModal visible={ticketBuyAccount} ticket_id={ticketId} modalClose={() => setTicketBuyAccount(false)} />}
        </>
    )
}

export default Home;