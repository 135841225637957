import { useEffect, useState } from "react";
import { IoCloseOutline, IoEllipseSharp } from "react-icons/io5";
import { generalObj } from "../../constant/objects_types";
import { addEventComment, postedTime } from "../../action/generalFunc";
import ProfileGenerator from "../ProfileGenerator";
import InputField from "../InputField";
import { SendInputIcoSvg } from "../../styles/IconStyle";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

const EventCommentItem = ({ item, index }:{
    item:generalObj,
    index: number
}) => {
    const [timeStamp, setTimeStamp] = useState<string | null>(null);
    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        const getTimeStamp = setInterval (() => {
            let postedTimeResult:string = postedTime(item.created_at);
            setTimeStamp(postedTimeResult);
        }, 1000);

        return (() => {
            clearInterval(getTimeStamp);
        })
    }, [item]);

    return (
        <>
            {account && (
                <div style={{paddingBlock:"15px", paddingTop:`${index === 0? "0px" : "24px"}`, marginTop:`${index === 0? "32px" : "24px"}`, display:"flex", justifyContent:'space-between', gap:"12px", borderTopWidth:`${index === 0 ? "0px" : "1px"}`, borderTopStyle:"solid", borderTopColor:'rgba(255, 255, 255, 0.1)'}}>
                    {item.user.profile_picture ? (
                        <img src={item.user.profile_picture} className="profilePictureUser" style={{width:"32px", height:"32px", borderRadius:"32px"}} alt="comment icon" />
                    ): (
                        <ProfileGenerator title={item.user.username[0].toUpperCase()} constStyle={{width:"32px", height:"32px", borderRadius:"32px"}} textStyle={{fontSize:"14px"}} useAccount={account.username === item.user.username ? true : false} />
                    )}
                    <div style={{width:'90%'}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <div className="postOwnerText" style={{color:'rgba(255, 255, 255, 0.6)', marginLeft:"0px"}}>{item.user.fullname}</div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div className="inputLabel">{timeStamp}</div>
                            </div>
                        </div>
                        <div className="itemOwner" style={{marginTop:"5px", fontSize:"14px", lineHeight:"19px", fontFamily:'notosans-regular'}}>{item.comment}</div>
                    </div>
                </div>
            )}
        </>
    )
}

const EventComment = ({ visible, event_id, eventObj, modalClose, setComments }:{
    visible:boolean,
    event_id: string,
    eventObj: generalObj,
    setComments : (val:generalObj[]) => void,
    modalClose: () => void
}) => {
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [commentList, setCommentList] = useState<generalObj[]>([]);
    const [commentInput, setCommentInput] = useState<string>("");
    const [sendActive, setSendActive] = useState<boolean>(false);
    const account = useSelector((state:RootState) => state.account);
    const idMap:generalObj = {
        "influence": 159,
        "ktechfest": 170
    };
    useEffect(() => {
        if (!eventObj.comments) return;
        setCommentList(eventObj.comments);
    }, [eventObj])

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const sendComments = async () => {
        if (!sendActive || !account) return;

        let id_object:number = idMap[event_id] ? idMap[event_id] : event_id; 

        const result:generalObj = await addEventComment(account.token, `${id_object}`, commentInput);
        if (result.status) {
            let new_comment = {...result.data.data, user:{"fullname": account.fullname, "id": account.id, "profile_picture": account.profileImage, "username": account.username}}
            setComments([new_comment, ...commentList]);
            setCommentInput("");
            setSendActive(false);
        }
    }

    return (
        <>
            {firstShow && (
                <>
                    <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Comments</div>
                                        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                            <IoEllipseSharp size={"4px"} color="#FFF" style={{marginInline:"5px"}} />
                                        </div>
                                        <div style={{fontSize:'14px', color:"rgba(255, 255, 255, 0.60)", fontWeight:'500'}}>{commentList.length}</div>
                                    </div>
                                    <div style={{display:'flex'}} onClick={() => {modalClose()}}>
                                        <IoCloseOutline size={24} color={"#FFF"} />
                                    </div>
                                </div>
                                <div style={{width:'100%', height: "calc(100% - 80px)", overflowX: "hidden"}}>
                                    <div style={{paddingBottom:"50px"}}>
                                        {commentList.map((item, index) => (
                                            <EventCommentItem item={item} index={index} key={index} />
                                        ))}
                                    </div>

                                </div>
                                <div className="bgMain" style={{paddingBlock:"12px", paddingBottom:"25px", position:'absolute', bottom:0, borderTop:"1px solid rgba(255, 255, 255, 0.10)", left:0, width:"100%"}}>
                                    <div style={{paddingInline: "20px", display:'flex', justifyContent:'space-between', alignItems:'center', gap:"10px"}}>
                                        <ProfileGenerator constStyle={{width:"32px", height:"32px"}} textStyle={{fontSize:"14px"}} useAccount={true} />
                                        <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:"90%"}}>
                                            <InputField id={"commentInput"} inputProp={{value:commentInput, placeholder:'Add a comment'}} isIcon={<SendInputIcoSvg active={sendActive} />} customInputField={{marginTop:0}} customInputTextCont={{marginTop:0}} onTextChange={(val)=>{
                                                setCommentInput(val);
                                                if (val.length > 0) {
                                                    setSendActive(true);
                                                }else {
                                                    setSendActive(false);
                                                }
                                            }} iconPress={() => sendComments()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default EventComment;