import { useEffect, useState } from "react";
import BackArrow from "../BackArrow";
import ProfileGenerator from "../ProfileGenerator";
import { EditIconSvg, RegularIconSvg, VipIconSvg, VvipIconSvg } from "../../styles/IconStyle";
import { generalObj } from "../../constant/objects_types";
import { convertTicketCategory, deriveInitialCharge, deriveInitialPrice, ticketPrePurchase, ticketPurchase } from "../../action/generalFunc";
import FooterAction from "../FooterAction";
import ButtonFrame from "../ButtonFrame";
import TicketModal from "./TicketModal";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { usePaystackPayment } from "react-paystack";
import { PaystackProps } from "react-paystack/dist/types";
import { PAYSTACK_PUBLIC_KEY, SYMBOL } from "../../constant/constant";
import GuestUserInfoModal from "../modal/GuestUserInfoModal";
import ApplyDiscountModal from "../modal/ApplyDiscountModal";


const PaystackHook = ({ config, account, ticket, discount, loading, onSuccess, onClose }:{
    config: generalObj,
    account: generalObj,
    ticket: generalObj[],
    discount: generalObj | null,
    loading: (val:boolean) => void,
    onSuccess: () => void,
    onClose: () => void
}) => {
    const payConfigProp:PaystackProps = {
        email: config.email,
        publicKey: config.publicKey,
        amount: config.amount * 100,
        reference: config.reference
    } 
    const initializePayment = usePaystackPayment(payConfigProp);

    const triggerPrePurchase = async () => {
        loading(true);

        const purchased_ticket = ticket.filter((item) => item.active);
        if (!purchased_ticket.length) {
            loading(false);
            alert('No ticket selected');
            return;
        }

        const ticket_data:generalObj = {
            tx_ref: config.reference,
            quantity: purchased_ticket[0].qty,
            ticket_id: purchased_ticket[0].id,
        }

        if (discount) {
            ticket_data.discount_info = discount;
        }

        const result:generalObj = await ticketPrePurchase(account.token, ticket_data);
        loading(false);
        if (result.status) {
            initializePayment(onSuccess, onClose);
        }else {
            alert(result.message);
        }
    }

    return (
        <ButtonFrame title="Checkout" contStyle={{marginTop: 12, backgroundColor:'#DB012E', borderWidth:0}} onPress={() => triggerPrePurchase()} /> 
    );
};

const CheckOut = ({ visible, myTicketCheck, isLoading, updateMyTicket, modalClose, checkOut }:{
    visible:boolean,
    myTicketCheck: generalObj[] | null,
    isLoading: (val:boolean) => void,
    updateMyTicket: (val:generalObj[] | null) => void,
    modalClose:() => void,
    checkOut: (val:generalObj) => void
}) => {
    const [checkoutTotal, setCheckoutTotal] = useState<number>(0);
    const [checkoutSubtotal, setCheckoutSubtotal] = useState<number>(0);
    const [discountValue, setDiscountValue] = useState<number>(0);
    const [refereshTotal, setRefreshTotal] = useState<boolean>(false);
    const [firstShow, setFirstShow] = useState<boolean>(false);

    const [myTicket, setMyTicket] = useState<generalObj[] | null>(null);
    const [itemForSelection, setItemForSelection] = useState<generalObj | null>(null);
    const [edittingModal, setEdittingModal] = useState<boolean>(false);

    const account = useSelector((state:RootState) => state.account);
    const [anonyAccount, setAnonyAccount] = useState<generalObj>({
        fullname: "",
        email: "",
        active: false,
        filled: false
    });

    const [discountApply, setDiscountApply] = useState<boolean>(false);
    const [discountObj, setDiscountObj] = useState<generalObj | null>(null);

    const [payConfig, setPayConfig] = useState<generalObj>({
        publicKey: PAYSTACK_PUBLIC_KEY,
        reference: (new Date()).getTime().toString()
    });

    
    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!account) return;
        setPayConfig((prevPayConfig) => ({...prevPayConfig, email:account.email}));
    }, [account]);

    useEffect(() => {
        if (refereshTotal || visible) {
            let total = 0;
            
            const loadmyTicket = async () => {
                const result = myTicketCheck;
                let myPrice = 0;
                let ticket_qty = 1;
                if (result) {
                    let myTicketPrice = result.filter((item) => item.active);
                    myTicketPrice.forEach(element => {
                        myPrice += parseFloat(element.price);
                        ticket_qty = element.qty;
                    });
                    setMyTicket(result);
                }else {
                    myPrice += 0;
                    setMyTicket(null);
                }
                total += myPrice;
                let sub_total = total * ticket_qty;
                let main_total = sub_total >= discountValue ? sub_total - discountValue : 0;
                setCheckoutSubtotal(sub_total);
                setCheckoutTotal(main_total);
                setPayConfig((prevPayConfig) => ({...prevPayConfig, amount:main_total > 0 ? main_total : 0, reference: (new Date()).getTime().toString()}));
            }
            loadmyTicket();
            setRefreshTotal(false);
        }else {
            return;
        }
    }, [visible, refereshTotal, myTicketCheck, discountValue]);

    const updateTicket = async (action:string, id:generalObj|null=null) => {
        switch(action) {
            case "ticket_select":
                if (!id || !id.ticket || !account) return;
                if (id.id === account.id) {
                    const result = myTicketCheck;
                    if (result) {
                        const new_ticket = result.filter((item) => {
                            if (item.id === id.ticket) {
                                item.active = true;
                                item.qty = id.quantity;
                            }else {
                                item.active = false
                            }
                            return item
                        });
                        updateMyTicket(new_ticket);
                    } 
                }
                setEdittingModal(false);
                setItemForSelection(null);
                setRefreshTotal(true);
                break;
            // case "remove_myTicket":
            //     setMyTicket(null);
            //     updateMyTicket(null);
            //     setIsMyRemoved(true);
            //     setRefreshTotal(true);

            //     break;
            default:
                alert('Action not recognized.');
        }
    }

    const prepareTicketForEdit = (item:generalObj) => {
        setItemForSelection(item);
        setEdittingModal(true);
    }

    // const paymentComplete = async (isVal ?:generalObj) => {
    //     if (!myTicket) return;
    //     let aAccount = isVal ? isVal : anonyAccount; 
    //     if ((!account || !account.isAuthenticated) && !aAccount.filled) return;
    //     isLoading(true);
    //     const purchased_ticket = myTicket.filter((item) => item.active);
    //     if (!purchased_ticket.length) {
    //         isLoading(false);
    //         return;
    //     }

    //     const ticket_data:generalObj = {
    //         tx_ref: payConfig.reference,
    //         quantity: purchased_ticket[0].qty,
    //         ticket_id: purchased_ticket[0].id
    //     }
        
    //     if (discountObj) {
    //         ticket_data.discount_info = discountObj;
    //     }

    //     if (!account || !account.isAuthenticated) {
    //         ticket_data.fullname = aAccount.fullname;
    //         ticket_data.email = aAccount.email;
    //         ticket_data.phone_number = aAccount.phone_number;
    //     }
        
    //     const result:generalObj = await ticketPurchase(account && account.isAuthenticated ? account.token : '', ticket_data);
    //     if (result.status) {
    //         checkOut(aAccount);
    //     }else {
    //         alert(result.message);
    //     }
    //     isLoading(false);
    // }
    const paymentComplete = async (isVal ?:generalObj) => {
        let aAccount = isVal ? isVal : anonyAccount; 
        if ((!account || !account.isAuthenticated) && !aAccount.filled) return;
        isLoading(true);
        if (payConfig.amount > 0) {
            checkOut(aAccount);
        }else {
            if (!myTicket) {
                alert('No ticket selected');
                return;
            }

            const purchased_ticket = myTicket.filter((item) => item.active);
            if (!purchased_ticket.length) {
                isLoading(false);
                return;
            }

            const ticket_data:generalObj = {
                tx_ref: payConfig.reference,
                quantity: purchased_ticket[0].qty,
                ticket_id: purchased_ticket[0].id
            }

            if (!account || !account.isAuthenticated) {
                ticket_data.fullname = aAccount.fullname;
                ticket_data.email = aAccount.email;
                ticket_data.phone_number = aAccount.phone_number;
            }
            
            const result:generalObj = await ticketPurchase(account && account.isAuthenticated ? account.token : '', ticket_data);
            if (result.status) {
                checkOut(aAccount);
            }else {
                alert(result.message);
            }
        }
        // const ticket_data:generalObj = {
        //     tx_ref: payConfig.reference,
        // }
        // const result:generalObj = await ticketPurchaseComplete(account && account.isAuthenticated ? account.token : '', ticket_data);
        // if (result.status) {
        //     checkOut(aAccount);
        // }else {
        //     alert(result.message);
        // }
        isLoading(false);
    }

    // const payIncomplete = async (isVal ?:generalObj) => {
    //     if (!payConfig) return;
    //     let aAccount = isVal ? isVal : anonyAccount; 
    //     if ((!account || !account.isAuthenticated) && !aAccount.filled) return;
    //     isLoading(true);

    //     const ticket_data:generalObj = {
    //         reference: payConfig.reference
    //     }

    //     const result:generalObj = await ticketPurchaseVerify(ticket_data);
    //     if (result.status) {
    //         paymentComplete(aAccount);
    //     }else {
    //         alert(result.message);
    //         isLoading(false);
    //     }
    // }

    const setGuestUser = (val:generalObj, isClosed?:boolean) => {
        setAnonyAccount({...anonyAccount, ...val, filled:true, active:false});
        setPayConfig((prevPayConfig) => ({...prevPayConfig, email:val.email}));
        if (isClosed) {
            // paymentComplete({...val, filled:true});
            alert('Payment terminated');
            window.location.reload();
        }else {
            paymentComplete({...val, filled:true});
        }
    }

    const closeTicketPurchase = () => {
        alert('Payment terminated');
        window.location.reload();
    }

    const updatePayment = (val:generalObj, email:string) => {
        let discount_value = val.discount_type === 1 ? val.value : (val.value / 100) * checkoutSubtotal;
        val.discount_value = discount_value;
        setDiscountObj(val);
        setDiscountValue(discount_value);
        setDiscountApply(false);
        if (!account || !account.isAuthenticated) {
            setPayConfig((prevPayConfig) => ({...prevPayConfig, email:email}));
        }
    }

    return (
        <>
            {firstShow && (
                <>
                    <div id="mpe" className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                        <div onClick={modalClose} className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Ticket Checkout</div>
                                    </div>
                                </div>
                                
                                <div>
                                    {myTicket && myTicket.length > 0 && (
                                        <>
                                            <div className="sectionTitle" style={{color:'rgba(255, 255, 255, 0.6)', marginTop:"32px", marginBottom:"16px", fontSize:"16px", lineHeight:"16px"}}>My Ticket</div>    
                                            <div>
                                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBlock:"0px"}}>
                                                    <div style={{display:'flex', alignItems:'center'}}>
                                                        <ProfileGenerator textStyle={{fontSize:12, lineHeight:16}} constStyle={{width:24, height:24, marginRight:5}} useAccount={true} />
                                                        <div className="postOwnerText" style={{marginLeft:"10px"}}>{account && account.isAuthenticated ? account.fullname : "Anonymous User"}</div>
                                                    </div>
                                                    <div style={{flexDirection:'row', alignItems:'center'}}>
                                                        <button onClick={()=>prepareTicketForEdit({
                                                            ...account,
                                                            ticket: myTicket
                                                        })}>
                                                            <EditIconSvg />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div style={{marginTop:"16px"}}>
                                                    {myTicket.map((ticket:generalObj, index:number) => (
                                                        ticket.active && (
                                                            <div key={index} style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
                                                                <div style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                                                    {ticket.category === 'vvip' && <VvipIconSvg />}
                                                                    {ticket.category === 'vip' && <VipIconSvg />}
                                                                    {(ticket.category === 'regular' || (ticket.category !== 'vvip' && ticket.category !== 'vip')) && <RegularIconSvg />}
                                                                    <div>
                                                                        <div className="itemOwner" style={{fontWeight:'600', marginLeft:"5px", lineHeight:"22px"}}>{convertTicketCategory(ticket.category, ticket.title)}</div>
                                                                        <div className="itemOwner" style={{fontWeight:'600', marginLeft:"5px", lineHeight:"22px"}}>x {ticket.qty} {ticket.qty > 1 ? "Tickets" : "Ticket"}</div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="itemOwner" style={{fontWeight:'600', lineHeight:"22px"}}>{ticket.price && ticket.price > 0 ? `${SYMBOL}${parseFloat(`${ticket.price}`).toLocaleString()}` : 'Free' }</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    ))}

                                                </div>
                                            </div>
                                            {/* Discount Code Link */}
                                            <div className='forgotText' style={{marginTop:'10px'}}>
                                                <button onClick={() => setDiscountApply(true)}>
                                                    <span>Discount code?</span>
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>

                            </div>
                            <FooterAction contStyle={{width:"calc(100% - 40px)", backgroundColor:'#1D1716', paddingTop:"24px"}}>
                                <>
                                    {/* {(id && id === '105') ? ( */}
                                    <>
                                        <div style={{display:'flex', marginBottom:"10px", justifyContent:'space-between'}}>
                                            <div className="sectionTopLink" style={{color:'rgba(255, 255, 255, 0.6)'}}>Ticket Price:</div>
                                            <div className="sectionTopLink" style={{color:'#FFF'}}>{checkoutTotal > 0 ? `${SYMBOL}${parseFloat(`${deriveInitialPrice(checkoutTotal)}`).toLocaleString()}` : "Free"}</div>
                                        </div>
                                        <div style={{display:'flex', marginBottom:"10px", justifyContent:'space-between'}}>
                                            <div className="sectionTopLink" style={{color:'rgba(255, 255, 255, 0.6)'}}>Service Fee:</div>
                                            <div className="sectionTopLink" style={{color:'#FFF'}}>{checkoutTotal > 0 ? `${SYMBOL}${parseFloat(`${deriveInitialCharge(checkoutTotal)}`).toLocaleString()}` : "0"}</div>
                                        </div>
                                        <div style={{width: '100%', height: "1px", backgroundColor:'rgba(255, 255, 255, 0.08)', margin: "15px 0px"}}>
                                        </div>
                                    </>
                                    {/* ): null} */}
                                    {discountValue ? (
                                        <>
                                            {/* <div style={{display:'flex', marginBottom:"10px", justifyContent:'space-between'}}>
                                                <div className="sectionTopLink" style={{color:'rgba(255, 255, 255, 0.6)'}}>Subtotal Amount</div>
                                                <div className="sectionTopLink" style={{color:'#FFF'}}>{checkoutSubtotal > 0 ? `${SYMBOL}${parseFloat(`${checkoutSubtotal}`).toLocaleString()}` : "Free"}</div>
                                            </div> */}
                                            <div style={{display:'flex', marginBottom:"10px", justifyContent:'space-between'}}>
                                                <div className="sectionTopLink" style={{color:'rgba(255, 255, 255, 0.6)'}}>Discount</div>
                                                <div className="sectionTopLink" style={{color:'#FFF'}}>{discountValue > 0 ? `${SYMBOL}${parseFloat(`${discountValue}`).toLocaleString()}` : "0"}</div>
                                            </div>
                                            <div style={{width: '100%', height: "1px", backgroundColor:'rgba(255, 255, 255, 0.08)', margin: "15px 0px"}}>
                                            </div>
                                        </>
                                    ): null}
                                    <div style={{display:'flex', marginBottom:"32px", justifyContent:'space-between'}}>
                                        <div className="sectionTopLink" style={{color:'rgba(255, 255, 255, 0.6)'}}>Total Amount</div>
                                        <div className="sectionTopLink" style={{color:'#FFF'}}>{checkoutTotal > 0 ? `${SYMBOL}${parseFloat(`${checkoutTotal}`).toLocaleString()}` : "Free"}</div>
                                    </div>
                                    
                                    {(account && account.isAuthenticated) ||  anonyAccount.filled ? (
                                        <>
                                            {payConfig.amount && payConfig.email && account && myTicket ? (
                                                <PaystackHook config={payConfig} account={account} ticket={myTicket} discount={discountObj} loading={(val) => isLoading(val)} onSuccess={() => paymentComplete()} onClose={() => closeTicketPurchase()} />
                                            ): null}

                                            {payConfig.amount === 0 && (
                                                <ButtonFrame title="Checkout" contStyle={{marginTop: 12, backgroundColor:'#DB012E', borderWidth:0}} onPress={() => paymentComplete()} /> 
                                            )}
                                        </>   
                                    ):(
                                        <ButtonFrame title="Enter Your Information" contStyle={{marginTop: 12, backgroundColor:'#DB012E', borderWidth:0}} onPress={() => setAnonyAccount({...anonyAccount, active:true})} /> 
                                    )}
                                </>
                            </FooterAction>
                            {itemForSelection && (
                                <TicketModal visible={edittingModal} ticketObject={itemForSelection} onSelect={(newObject)=> updateTicket('ticket_select', newObject)} modalClose={() => {
                                    setItemForSelection(null);
                                    setEdittingModal(false);
                                }} />
                            )}

                            {anonyAccount.active && myTicket && <GuestUserInfoModal visible={anonyAccount.active} config={payConfig} ticket={myTicket} isLoading={(val) => isLoading(val)} discount={discountObj} modalClose={() => setAnonyAccount({...anonyAccount, active:false})} onComplete={(val) => setGuestUser(val, false)} onIncomplete={(val) => setGuestUser(val, true)} />}
                            {discountApply && <ApplyDiscountModal visible={discountApply} account={account} isLoading={(val) => isLoading(val)} modalClose={() => setDiscountApply(false)} onComplete={(val, email) => updatePayment(val, email)} />}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default CheckOut;